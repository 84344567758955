import React from 'react';

import PassForm from '../components/PassForm';

const LogIn = () => {
  return (
  <section className='bg-primary pb-6 fixed w-full'>
    <div className='container mx-auto pb-10 text-justify'>
      <h2 className='h2 mb-6 text-center xl:text-left'>Wprowadź hasło</h2>
      <div className='w-full xl:flex xl:max-w-[734px]'><PassForm /></div>
    </div>
  </section>
  )
};

export default LogIn;
