import React, { useContext } from 'react';

import { useParams } from 'react-router-dom';

import useFetch from '../hooks/useFetch';

import RelatedProducts from '../components/RelatedProducts';

import { CartContext } from '../context/CartContext';

import Markdown from 'react-markdown';

const ProductDetails = () => {
  const {addToCart} = useContext(CartContext);
  const {id} = useParams();
  const {data} = useFetch(`/products?populate=*&filters[id][$eq]=${id}`);
  if (!data)
    return <div className='container mx-auto'>Ładowanie...</div>
  const categoryTitle = data[0].categories[0].title;
  return (
  <div className='mb-16 pt-44 lg:pt-[30px] xl:pt-0'>
    <div className='container mx-auto'>
      <div className='flex flex-col lg:flex-row gap-[30px] mb-[30px]'>
        <div className='flex-1 lg:max-w-[40%] lg:h-[540px] grad rounded-lg flex justify-center items-center'>
          <img src={`${process.env.REACT_APP_ASSETS_URL}${data[0]?.image?.url}`} alt='' className='w-full max-w-[65%] object-scale-down' />
        </div>
        <div className='flex-1 bg-primary p-12 xl:p-20 rounded-lg flex flex-col justify-center'>
          <div className='uppercase text-accent text-lg font-medium mb-2'>{data[0].categories[0].title}</div>
          <h2 className='h2 mb-4'>{data[0].title}</h2>
	  {/* <p className='mb-12'>{data[0].description}</p> */}
	  <p className='mb-12 text-justify'><Markdown>{data[0].richdescription}</Markdown></p>
          <div className='flex items-center gap-x-8'>
            <div className='text-3xl text-accent font-semibold'>{data[0].price} PLN</div>
            <button onClick={()=>addToCart(data, id)} className='btn btn-accent'>Do koszyka</button>
          </div>
        </div>
      </div>
    </div>
    <RelatedProducts categoryTitle={categoryTitle} />
  </div>);
};

export default ProductDetails;
