import React, { useEffect, useState, useContext } from 'react';

import { FiLogIn } from "react-icons/fi";

import { useNavigate } from 'react-router-dom';

import { CartContext } from '../context/CartContext';

const PassForm = () => {
  const navigate = useNavigate();
  const [passTerm, setPassTerm] = useState('');
  const [isAnimating, setIsAnimating] = useState(false);
  const { setLogged } = useContext(CartContext);

  useEffect(()=>{
    const timeout = setTimeout(()=>{
      setIsAnimating(false);
    }, 1000);
    return ()=>clearTimeout(timeout);
  });

  const handlePassInput = (e) => {
    setPassTerm(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //console.log(searchTerm);
    if ( passTerm.length > 0 ) {
      if ( passTerm === 'KPOns' )
        setLogged(true);
      document.querySelector('input').value = '';
      setPassTerm('');
    } else {
      // empty search
      setIsAnimating(true);
    }
  };

  return <form
    onSubmit={handleSubmit} className={`${isAnimating ? 'animate-shake' : 'animate-none'} w-full relative`}>
    <input
     onChange={handlePassInput}
     className='input' type='password' placeholder='Wprowadź hasło' />
    <button className='btn btn-accent absolute top-0 right-0 rounded-tl-none rounded-bl-none'><FiLogIn className='text-xl' /></button>
  </form>;
};

export default PassForm;
