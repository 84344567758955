import React from 'react';

import KpoPlakat from '../img/kpo-plakat.png';

const Kpo = () => {
  return (
  <section>
    <div className='container mx-auto pb-10 text-justify'>
      <h2 className='h2 mb-6 text-center xl:text-left'>Bo współpraca zawsze się opłaca!</h2>
      <p className='mb-2'>Z radością informujemy, że nasza fundacja otrzymała dofinansowanie z Krajowego Planu Odbudowy. Środki te zostaną przeznaczone na zakup niezbędnych sprzętów i pomocy, które umożliwią nam skuteczną realizację powierzonych zadań. Dzięki temu wsparciu będziemy mogli jeszcze efektywniej działać na rzecz społeczności, realizując nasze inicjatywy z jeszcze większym rozmachem i profesjonalizmem. To ważny krok w kierunku wzmocnienia naszych działań i zwiększenia ich zasięgu, co przyniesie korzyści wielu osobom i grupom, którym pomagamy. Jesteśmy wdzięczni za to wsparcie i pełni entuzjazmu patrzymy w przyszłość, gotowi na nowe wyzwania i projekty.</p>
      <div className='py-6'>
	  <img className='mx-auto' src={KpoPlakat} alt='Krajowy Plan Odbudowy' />
      </div>
      <p className='mb-2 font-bold'>Czym jest KPO?</p>
      <p className='mb-2'>Jest to program składający się z licznych reform i inwestycji, którego celem jest wzmocnienie polskiej gospodarki oraz sprawienie, że będzie ona bardziej odporna na kryzysy. Wśród obszarów, które wspiera KPO znajduje się poprawa jakości instytucji i warunków realizacji Krajowego Planu Odbudowy. Program, który my realizujemy to „Odporność oraz rozwój ekonomii społecznej i przedsiębiorczości społecznej”.</p>
      <p className='mb-2'>Wśród głównych celów naszej działalności znajdują się:</p>
      <p className='mb-2'>1) Reintegracja osób zagrożonych wykluczeniem społecznym w przedsiębiorstwach społecznych i podmiotach zatrudnienia socjalnego, poprzez zakup środków trwałych, wyposażenia, przeprowadzenie remontu lub adaptacja pomieszczeń, niezbędnych do prowadzenia takiej działalności</p>
      <p className='mb-2'>2) Budowanie potencjału przedsiębiorstw społecznych i podmiotów ekonomii społecznej do realizacji zdeinstytucjonalizowanych usług społecznych.</p>
      <p className='mb-2'>3) Wzmacnianie odporności i rozwój przedsiębiorstw społecznych i podmiotów ekonomii społecznej.</p>
      <p className='mb-2'>Rozwój Fundacji w nowym obszarze pozwoli na zwiększenie zatrudnienia i rozwój w zakresie reintegracji społecznej i zawodowej osób zagrożonych wykluczeniem społecznym. Przy czym wspomagane są także inne podmioty ekonomii społecznej co pozwoli na zwiększenie skali oddziaływania. Zakup niezbędnego wyposażenia oraz wdrożenie sklepu internetowego wzmocni potencjał innowacyjny i rozwojowy organizacji. Uzyskane dodatkowe źródło dochodu pozwolą na skuteczną reintegrację zagrożonych wykluczeniem społecznym pracowników Fundacji. Bezpośredni udział pracowników w procesie rozwoju pozwoli na nabycie i podniesienie kompetencji i kwalifikacji. Wprowadzona modernizacja i poszerzenie działalności umożliwi dopasowanie się organizacji do zmieniających się uwarunkowań rynkowych i społecznych oraz skuteczną odpowiedź na aktualne problemy społeczne.</p>
      <p className='mb-2'>Jednym z efektów wsparcia jest niniejszy sklep internetowy.</p>
    </div>
  </section>
  )
};

export default Kpo;
